import {
  CURRENCY,
  ENDPOINT,
  GET_PARAMS
} from '../config';


export const toggleApplyCouponBtn = (couponInput, applyCouponBtn, couponBlockParams) => {
  const { emptyCouponClass } = couponBlockParams;

  if (couponInput.val()) {
    applyCouponBtn.removeClass(emptyCouponClass);
    return;
  }

  applyCouponBtn.addClass(emptyCouponClass);
};

export const toggleCouponBlock = (couponBlock, toggleCouponBlockBtn, couponBlockParams) => {
  const { activeClass, showCouponText, hideCouponText } = couponBlockParams;
  // hide coupon block
  if (couponBlock.hasClass(activeClass)) {
    couponBlock.removeClass(activeClass);
    toggleCouponBlockBtn.html(showCouponText);
    couponBlock.hide();
    return;
  }

  // show coupon block
  couponBlock.show();
  toggleCouponBlockBtn.html(hideCouponText);
  couponBlock.addClass(activeClass);
};

// check is coupon a distributor code
export const doCheckIsCouponDistributorCode = (coupon) =>
  $.ajax({
    url: ENDPOINT.GIFT_CODE_VALIDATE,
    contentType: 'application/json',
    type: 'POST',
    data: JSON.stringify({
      code: coupon
    })
  });

export const handleDropinToken = (callback = () => {}, errorHandler = () => {}) => {
  if (window.braintree_token) {
    callback();
    return;
  }

  $.ajax({
    url: ENDPOINT.GET_BRAINTREE_TOKEN,
    contentType: 'application/json',
    type: 'GET'
  }).then((data) => {
      window.braintree_token = data.token;
      callback();
    }, (err) => {
      errorHandler(err);
    });
};

export const getSelectedOfferDetails = (selectedOffer, amountOff=0, planAttr, userEmail=null) => {
  const { slug, offerPeriod, price, backUrl, offerPeriodDisplay } = planAttr;
  const planPeriod = selectedOffer.attr(offerPeriod);
  const planFullPrice = (selectedOffer.attr(price) / 100).toFixed(2);
  const planPriceWithDiscount = (planFullPrice - amountOff).toFixed(2);
  const planNewPrice = planPriceWithDiscount < 0 ? 0 : planPriceWithDiscount;
  const planFormattedNewPrice = parseFloat(planNewPrice).toFixed(2);
  const planSlug = selectedOffer.attr(slug);
  const planBackUrl = userEmail
    ? selectedOffer.attr(backUrl) + `?${GET_PARAMS.EMAIL}=${encodeURIComponent(userEmail)}`
    : selectedOffer.attr(backUrl);
  const planPeriodDisplay = selectedOffer.attr(offerPeriodDisplay) || planPeriod;

  return {
    planPeriod,
    planFullPrice,
    planFormattedNewPrice,
    planSlug,
    planBackUrl,
    planPeriodDisplay
  };
};

export const getShippingPriceInCents = (shippingCountriesSelect) => {
  const result = parseInt($(shippingCountriesSelect).find('option:selected').attr('data-price'));
  return result;
};

const getEbloxPriceInCents = () => {
  const result = parseInt(ebloxAddonPrice);
  return result;
};

export const getTotalPricesInCents = (
  planFullPriceInCents,
  isTrialActive=false,
  isEbloxAdded=false,
  activeCoupon=false,
  amountOff=0,
  shippingCountriesSelect
) => {
  const mainSubscriptionPriceInCents = parseFloat(planFullPriceInCents);
  let subscriptionPriceInCents = mainSubscriptionPriceInCents;
  let ebloxAndShippingPriceInCents = 0;
  let totalPriceInCents = 0;

  if (isTrialActive) subscriptionPriceInCents = 0;

  if (isEbloxAdded) {
    ebloxAndShippingPriceInCents =
      getShippingPriceInCents(shippingCountriesSelect) + getEbloxPriceInCents();
  }

  totalPriceInCents = subscriptionPriceInCents + ebloxAndShippingPriceInCents;

  if (activeCoupon) {
    const amountPriceInCents = parseFloat((amountOff * 100).toFixed());
    const priceAfterDiscount = parseFloat((totalPriceInCents - amountPriceInCents).toFixed());
    totalPriceInCents = priceAfterDiscount < 0 ? 0 : priceAfterDiscount;
  }

  const getPriceFor3ds = () => {
    /* eslint-disable max-len */
    const minCapFor3DSecureInCents = 1;
    let threeDSecurePrice = mainSubscriptionPriceInCents + ebloxAndShippingPriceInCents;

    if (activeCoupon) {
      const amountPriceInCents = parseFloat((amountOff * 100).toFixed());
      const threeDSecurePriceAfterDiscount = parseFloat((threeDSecurePrice - amountPriceInCents).toFixed());
      // fix for 3d secure, we CAN NOT use 0 as price for 3d secure
      threeDSecurePrice = threeDSecurePriceAfterDiscount <= 0 ? minCapFor3DSecureInCents : threeDSecurePriceAfterDiscount;
    }

    return threeDSecurePrice;
    /* eslint-enable max-len */
  };

  const priceFor3DSecure = getPriceFor3ds();

  return {totalPriceInCents, priceFor3DSecure};
};

export const getCurrencyPrice = (priceInCents) => {
  return `${CURRENCY}${(priceInCents / 100).toFixed(2).toString()}`;
};

export const setCheckoutBtnTitle = ($button, isTrialActive, checkoutTextAttr) => {
  if (isTrialActive) {
    $button.html($button.attr(checkoutTextAttr.trial));
    return;
  }

  $button.html($button.attr(checkoutTextAttr.noTrial));
};

export const setDefaultOfferSubmitText = ($button, submitText='Subscribe') => {
  if (submitText) $button.html(submitText);
};

export const defineTrialMessagePlural = (trialDuration, unit='day') => {
  return parseFloat(trialDuration) > 1
    ? `${trialDuration}-${unit}s`
    : `${trialDuration}-${unit}`;
};
