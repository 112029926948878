import {
  ACCOUNT_TYPES_STRING,
  ENDPOINT,
  GET_PARAMS,
  RESPONSE_CODES,
  SUBJECTS,
  URLS,
  PROMO_LANDINGS_URLS,
  ERROR_CODES
} from '../config';
import {
  fixPlusSymbolInProvidedEmail,
  getUrlParam,
  deleteUrlParam,
  replaceEmailWithLink,
  toggleButtonLoading,
  isFormValid
} from '../utils';


$(document).ready(function () {
  const $loginPage = $('[data-selector="log-in-page"]');
  const $logInModal = $('[data-selector="log-in-modal"]');
  const $kindsAcademyCom = $('[data-selector="kidsacademy-com-static"]');

  if (!$loginPage.length && !$logInModal.length && !$kindsAcademyCom.length) return;

  const noRedirectAttrName = 'data-no-redirect';

  const schoolLoginForm = '[data-selector="school-login-form"]';
  const familyLoginForm = '[data-selector="family-login-form"]';

  const specialInputSelector = '[data-selector="login-special-input"]';
  const loginEmailInput = '[data-selector="login-email-input"]';
  const loginPasswordInput = '[data-selector="login-password-input"]';
  const loginSubmitBtn = '[data-selector="login-submit-btn"]';
  const $loginError = tabId => $(`[data-selector="${tabId}-login-error"]`);

  const resetForm = '[data-selector="reset-form"]';
  const $resetForm = $(resetForm);
  const resetSubmit = '[data-selector="reset-submit"]';

  const $mainMenuNavbar = $('[data-selector="main-menu-navbar"]');
  const $mainMenuDropdown = $('[data-selector="main-menu-dropdown"]');
  const menuResizeBreakpoint = '991px';

  const $appDropdownToggle = $('[data-selector="app-dropdown-toggle"]');
  const $appDropdownMenu =  $('[data-selector="app-dropdown-menu"]');
  const $appDropdownMenuIcon = $('[data-selector="app-dropdown-menu-icon"]');
  const $appDropdown = $('[data-selector="app-dropdown"]');

  const onPageLoad = () => {
    openTabByGetParam();
    setEmailFromGetParam();
    initLoginTooltip();
    handleLoginRedirectError();
  };

  const openTabByGetParam = () => {
    const accountType = getUrlParam(GET_PARAMS.ACCOUNT_TYPE);
    const $tabToOpen = $(`[data-tab="${accountType}"]`);
    $tabToOpen.tab('show');
  };

  const setEmailFromGetParam = () => {
    const providedEmail = getUrlParam(GET_PARAMS.EMAIL);
    if(!providedEmail) return;

    const $familyEmailInput = $(loginEmailInput);
    $familyEmailInput.each((_, elem) => {
      $(elem).val(fixPlusSymbolInProvidedEmail(providedEmail));
    });
  };

  const handleLoginRedirectError = () => {
    const url = new URL(window.location.href);
    const tabId = url.searchParams.get(GET_PARAMS.ACCOUNT_TYPE);
    const error = url.searchParams.get(GET_PARAMS.ERROR);

    if (tabId && tabId.length && error && error.length) {
      const text = replaceEmailWithLink(error);
      $loginError(tabId).html(text).show();
    }
  };

  const resetParsleyErrors = () => {
    $(familyLoginForm).each((_, form) => $(form).parsley().reset());
    $(schoolLoginForm).each((_, form) => $(form).parsley().reset());
  };

  // init clever tooltip for login (login modal, login page)
  const initLoginTooltip = () => {
    $('[data-toggle="tooltip"]').tooltip({
      placement: 'auto bottom',
      container: 'body'
    });
  };

  const toggleFormLoading = ($form, isLoading) => {
    const $submitBtn = $form.find(loginSubmitBtn);
    toggleButtonLoading($submitBtn, isLoading);
  };

  const hideLoginFormError = () => {
    $loginError(ACCOUNT_TYPES_STRING.PARENT).hide();
    $loginError(ACCOUNT_TYPES_STRING.TEACHER).hide();
  };

  //handle form submit request errors
  const handleLoginError = (error, $errorBlock, tabName) => {
    if (error.redirect_url) {
      window.location.href = error.redirect_url;
      return;
    }

    $loginError(tabName).hide();
    let errorMessage = '';

    if (error && error.error && error.error_code) {
      if (error.error_code === ERROR_CODES.HAS_NO_SCHOOL_DISTRICT) {
        window.location.pathname = URLS.SIGN_UP_TEACHER;
        return;

      } else if (error.error_code === ERROR_CODES.AUTH_STUDENT_USER) {
        errorMessage = `Website login is for teachers and administrators only.
                        Students need to log on in the
                        apps: <a href="/apps">Click here for download links</a>`;

      } else if (error.error.indexOf('contact support') > -1) {
        let subject = SUBJECTS[RESPONSE_CODES[error.error_code]];
        if (!subject) subject = '';
        errorMessage = error.error.replace(
          'contact support',
          `<a href="mailto:info@kidsacademy.mobi?subject=${subject}">contact support</a>`
        );

      } else {
        errorMessage = 'Email or password is invalid';
      }

      $errorBlock.html(errorMessage);
      $errorBlock.show();
    }
  };

  const getActivePointAppDropdownMenu = () => {
    const $curURL = document.location.pathname;
    $appDropdownMenu.children().each(function() {
      const $linkHref = $(this).find('a').attr('href');
      if ($curURL.indexOf($linkHref) > -1 && $curURL.length === $linkHref.length) {
			  $(this).addClass('active');
        $appDropdownMenuIcon.appendTo($(this));
		  } else {
        $(this).removeClass('active');
      }
    });
  };

  const initAppsDropdown = () => {
    const $curURL = document.location.pathname;
    const $appLinkHref = $appDropdown.find('a').attr('href');

    if ($curURL.indexOf($appLinkHref) > -1) {
      $appDropdown.addClass('open');
      $appDropdownMenu.show();
    }
  };

  // handle family login form submit
  $(document)
    .on('submit', familyLoginForm, (e) => {
      e.preventDefault();

      const $form = $(e.target).closest(familyLoginForm);

      // To prevent bot spamming we use solution from https://www.thryv.com/blog/honeypot-technique/
      const hiddenInputVal = $form.find(specialInputSelector).val();
      if (hiddenInputVal) return;

      const email = $form.find(loginEmailInput).val();
      const password = $form.find(loginPasswordInput).val();
      const $errorBlock = $form.find($loginError(ACCOUNT_TYPES_STRING.PARENT));
      const loginParsleyForm = $form.parsley();

      loginParsleyForm.validate();
      if (!loginParsleyForm.isValid()) return;

      hideLoginFormError();
      toggleFormLoading($form, true);

      ga('send', 'event', 'Button', 'Login', 'Parent');

      $.ajax({
        url: ENDPOINT.USER_LOGIN,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify({
          email: email,
          password: password
        })
      }).then((response) => {
        if (response && response.error_code) {
          handleLoginError(response, $errorBlock, ACCOUNT_TYPES_STRING.PARENT);
          return;
        }

        if (window.fwdUrl) {
          window.location.href = window.fwdUrl;
          return;
        }

        let coupon = getUrlParam(GET_PARAMS.COUPON);
        const nextParam = getUrlParam(GET_PARAMS.NEXT);

        if(!coupon && nextParam) {
          const nextUrl = new URL(window.origin + nextParam);
          coupon = new URLSearchParams(nextUrl.search).get(GET_PARAMS.COUPON);
        }

        const couponGetParam = coupon ? `${GET_PARAMS.COUPON}=${coupon}` : '';

        // for users who logged in from modal on lp promo pages (worksheets, reading, math)
        // if (PROMO_LANDINGS_URLS.indexOf(location.pathname) > -1) {
        //   if (response.has_parent_subscription) {
        //     window.location = `${window.location.origin}/${URLS.PARENT_SUBSCRIPTION_SUCCESS}`;
        //     return;
        //   }
        //
        //   // reload the page if user doesnt has parent subscription
        //   // (do not redirect user from current page)
        //   window.location.reload();
        //   return;
        // }

        // if user logged in from promo subscription section
        // (button that opens login modal and set GET_PARAMS.MAKE_REDIRECT param to url
        // is in the error message in subscription-promo-payment)
        // if (getUrlParam(GET_PARAMS.MAKE_REDIRECT)) {
        //   if (response.has_parent_subscription) {
        //     window.location.href = `${window.location.origin}/${URLS.PARENT_SUBSCRIPTION_SUCCESS}`;
        //     return;
        //   }
        //
        //   if (couponGetParam) {
        //     const nextPath = `${window.location.origin}/${URLS.PARENT_SUBSCRIPTION_PLANS}`;
        //     window.location.href = `${nextPath}?${couponGetParam}`;
        //     return;
        //   }
        //
        //   // Don't redirect from page with promo subscription section
        //   // in case user doesn't have any active subscriptions
        //   if (!response.has_parent_subscription) {
        //     deleteUrlParam(GET_PARAMS.MAKE_REDIRECT);
        //     window.location.reload();
        //     return;
        //   }
        // }

        if (response && response.redirect_url) {
          const emailParam = `email=${btoa(email)}`;
          const getParams = couponGetParam ? `?${couponGetParam}&${emailParam}` : `?${emailParam}`;
          window.location.href = response.redirect_url + getParams;

          return;
        }

        window.location.reload();
      }, (error) => {
        toggleFormLoading($form, false);
        handleLoginError(JSON.parse(error.responseText), $errorBlock, ACCOUNT_TYPES_STRING.PARENT);
      });
    })
    // handle school login form submit
    .on('submit', schoolLoginForm, (e) => {
      e.preventDefault();

      const $form = $(e.target).closest(schoolLoginForm);
      // To prevent bot spamming we use solution from https://www.thryv.com/blog/honeypot-technique/
      const hiddenInputVal = $form.find(specialInputSelector).val();
      if (hiddenInputVal) return;

      const noNeedToRedirectFlag = $logInModal.attr(noRedirectAttrName) === 'true';
      const username = $form.find(loginEmailInput).val();
      const password = $form.find(loginPasswordInput).val();
      const $errorBlock = $loginError(ACCOUNT_TYPES_STRING.TEACHER);
      const loginParsleyForm = $form.parsley();

      loginParsleyForm.validate();
      if (!loginParsleyForm.isValid()) return;

      hideLoginFormError();
      toggleFormLoading($form, true);

      ga('send', 'event', 'Button', 'Login', 'Teacher');

      $.ajax({
        url: ENDPOINT.USER_LOGIN,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({email: username, password: password})
      }).then((response) => {
        if (response && response.error_code) {
          handleLoginError(response, $errorBlock, ACCOUNT_TYPES_STRING.TEACHER);
          return;
        }

        if (noNeedToRedirectFlag) {
          location.reload();
          return;
        }

        if (window.URLSearchParams) {
          const searchParams = new URLSearchParams(window.location.search);
          if (searchParams.has(GET_PARAMS.NEXT)) {
            window.location.href = searchParams.get(GET_PARAMS.NEXT);
            return;
          }
        }

        if (response && response.redirect_url) window.location.href = response.redirect_url;
      }, (error) => {
        toggleFormLoading($form, false);
        handleLoginError(JSON.parse(error.responseText), $errorBlock, ACCOUNT_TYPES_STRING.TEACHER);
      });
    })
    //trigger on tab change event
    .on('click', '[data-toggle="tab"]', () => {
      resetParsleyErrors();
      hideLoginFormError();
    })
    // password & pincode reset
    .on('click', resetSubmit, () => {
      isFormValid($resetForm);
    });

  $logInModal.on('show.bs.modal', resetParsleyErrors);

  $logInModal.on('hide.bs.modal', (e) => $(e.currentTarget).attr(noRedirectAttrName, false));

  $mainMenuNavbar
    .on('show.bs.collapse', () => {
      if (window.matchMedia(`(max-width: ${menuResizeBreakpoint})`).matches) {
        $mainMenuDropdown.addClass('open');
      }
    })
    .on('hidden.bs.collapse', () => $mainMenuDropdown.removeClass('open'));

  $(window).resize(() => {
    if (window.matchMedia(`(max-width: ${menuResizeBreakpoint})`).matches) {
      $mainMenuDropdown.addClass('open');
      $appDropdown.addClass('open');
      return;
    }

    if (window.matchMedia(`(min-width: ${menuResizeBreakpoint})`).matches) {
      $mainMenuDropdown.removeClass('open');
      $appDropdown.removeClass('open');
    }
  });

  $appDropdownToggle.on('click', (e) => {
    e.preventDefault();

    if (window.matchMedia(`(max-width: ${menuResizeBreakpoint})`).matches) return;

    if (!$appDropdown.hasClass('open')) {
      $appDropdown.addClass('open');
    } else {
      $appDropdown.removeClass('open');
    }

    $appDropdownMenu.slideToggle();
  });

  initAppsDropdown();
  getActivePointAppDropdownMenu();
  onPageLoad();
});


/* eslint-disable max-len */
// prevent mouse wheel click on download worksheet btn click
$(document).on('auxclick', '[data-selector="learning-resources-download"], [data-selector="download-worksheet-link"]', (e) => {
  if (e.button === 1) e.preventDefault();
});
/* eslint-enable max-len */
