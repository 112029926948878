import {
  braintreeDropinConfig,
  deleteUrlParam,
  getError,
  isThreeDSecureCanceled,
  processingPlurals,
  threeDSecureConfig,
  toggleButtonLoading,
  toggleMarkWorksheetAsDone,
  toggleAddWorksheetToFavorite,
  dispatchCustomEvent,
  storage,
  getUrlParam,
  deleteGetParamFromUrl,
  getUserSources,
  redirectToCompleteOnlineInTGC,
  handleCompleteOnline
} from '../utils';
import {
  LOCAL_STORAGE_KEYS,
  CURRENCY,
  CUSTOM_MESSAGE,
  DROPIN_SCRIPT_URL,
  ENDPOINT,
  GET_PARAMS,
  SUBSCRIPTION_PERIOD_MAPPING,
  SUBSCRIPTION_PERIODS,
  MODALS,
  WORKSHEET_ACTIONS,
  WEBSITE_CUSTOM_EVENTS,
  ACCOUNT_TYPES
} from '../config';
import {
  handleDropinToken
} from '../payment/subscription.utils';


$(document).ready(() => {
  const $websitePlansModal = $(MODALS.WEBSITE_PLANS_MODAL);
  if (!$websitePlansModal) return;

  let isSuccessStepShown = false;

  const adFreeSubscription = '[data-url="ad-free-subscription"]';

  const freePlanBtn = '[data-selector="blog-plans-modal-free-plan-btn"]';
  const planBtn = '[data-selector="blog-plans-modal-go-to-checkout-btn"]';

  const braintreeDropin = '#blog-plans-dropin-container';
  let braintreeDropinInstance = {};

  const $plansContainer = $('[data-selector="blog-plans-modal-plans"]');
  const $purchaseContainer = $('[data-selector="blog-plans-modal-purchase"]');
  const backToPlansBtn = '[data-selector="blog-plans-modal-back-btn"]';
  const $backToPlansBtn = $(backToPlansBtn);

  const $websitePlansModalTitle = $('[data-selector="blog-plans-modal-title"]');
  const successModalTitle = 'Welcome to Kids Academy Premium!';
  const defaultModalTitle = 'Try Kids Academy Premium!';
  const limitModalTitle = 'You\'ve reached your daily download limit!';
  let isTwoPlansModal = false;

  $websitePlansModalTitle.html(defaultModalTitle);
  const isWebsitePlansModalShow = storage.get(LOCAL_STORAGE_KEYS.SHOW_WEBSITE_PLANS_MODAL, true);
  if (isWebsitePlansModalShow === 'true') {
    // show plans modal with 3 plans
    $websitePlansModal.modal('show');
    ga('send', 'event', 'Monetization3_Three_Plans', 'PopUp_Shown', 'Load');
    isTwoPlansModal = false;
  }

  const goToPaymentBtn = '[data-selector="go-to-payment-btn"]';
  const $goToPaymentBtn = $(goToPaymentBtn);
  const submitPaymentBtn = '[data-selector="blog-plans-modal-pay-btn"]';
  const $submitPaymentBtn = $websitePlansModal.find(submitPaymentBtn);
  const $subscriptionError = $websitePlansModal.find('[data-selector="subscription-error"]');

  const $buySubscriptionSuccessStep = $('[data-selector="buy-subscription-success"]');
  const $subtitle = $('[data-selector="blog-plans-modal-subtitle"]');

  const trialDurationAttr = 'data-trial-duration';
  const priceAttr = 'data-price';
  const redirectAttr = 'data-redirect';
  const slugAttr = 'data-slug';
  const periodAttr = 'data-period';

  const planPeriodDisplayAttr = 'data-period-display';

  const $planPeriod = $websitePlansModal.find('[data-selector="plan-period-value"]');
  const $fullPrice = $websitePlansModal.find('[data-selector="plan-full-price-value"]');
  const $trialValue = $websitePlansModal.find('[data-selector="plan-trial-period-value"]');
  const $trialRow = $websitePlansModal.find('[data-selector="plan-trial-row"]');
  const $todayPrice = $websitePlansModal.find('[data-selector="plan-total-price-value"]');
  const $renewCondition = $websitePlansModal.find('[data-selector="plan-renew-condition"]');
  const twoPlansClassName = '_two-plans';

  const showWebsitePlansNoFreePopup = () => {
    // show plans modal with 2 plans
    $(MODALS.WEBSITE_PLANS_MODAL).modal('show');
    $plansContainer.addClass(twoPlansClassName);
    $subtitle.show();
    $websitePlansModalTitle.html(limitModalTitle);
    isTwoPlansModal = true;

    const worksheetAction = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_ACTION);
    if (worksheetAction === WORKSHEET_ACTIONS.GO_TO_WORKSHEET_CHECK_IN_TGC) {
      $websitePlansModalTitle.html(defaultModalTitle);
    }

    ga('send', 'event', 'Monetization3_Two_Plans', 'PopUp_Shown', 'Load');

    ga('send', 'event', 'Worksheets_Pay_Acc', 'PopUp shown', 'Click');
  };

  const onPageLoad = () => {
    const isWebsitePlansModalShow = getUrlParam(GET_PARAMS.DOWNLOAD_POPUP_LIMIT) === 'true';

    if (window.isLoggedIn
    && !window.HAS_PARENT_SUBSCRIPTION
    && window.isWebsitePlans > 0
    && isWebsitePlansModalShow) {
      showWebsitePlansNoFreePopup();
    }
  };

  document
    .addEventListener(WEBSITE_CUSTOM_EVENTS.SHOW_PLANS_POPUP_NO_FREE, () => {
      showWebsitePlansNoFreePopup();
      storage.delete(LOCAL_STORAGE_KEYS.WORKSHEET_ACTION);
    }, false);

  const renderSecondStepUI = ($button) => {
    $plansContainer.hide();
    $purchaseContainer.show();
    $subtitle.hide();

    toggleButtonLoading($button, false);

    const price = $button.attr(priceAttr);
    const trialDuration = parseInt($button.attr(trialDurationAttr));
    const planPeriodDisplay = $button.attr(planPeriodDisplayAttr);
    const planPeriod = $button.attr(periodAttr);

    const planPriceString = (price / 100).toFixed(2);
    /* eslint-disable max-len */
    const trialDurationString = processingPlurals(trialDuration, `${trialDuration} day`, `${trialDuration} days`);
    /* eslint-enable max-len */

    $planPeriod.html(planPeriodDisplay);
    $fullPrice.html(`${CURRENCY}${planPriceString}`);

    if (trialDuration === 0) {
      $trialRow.hide();
      $todayPrice.html(`${CURRENCY}${planPriceString}`);
    } else {
      $trialRow.show();
      $trialValue.html(trialDurationString);
      $todayPrice.html(`${CURRENCY}0.00`);

      if (planPeriod !== SUBSCRIPTION_PERIODS.LIFETIME) {
        const text = SUBSCRIPTION_PERIOD_MAPPING[planPeriod](planPriceString);
        $renewCondition.html(text);
      }
    }
  };

  const triggerAnalyticsOnCheckout = (response, slug, callback = () => {}) => {
    callback();
  };

  const initBraintreeDropin = (totalPriceInCents, priceFor3DSecure, slug, redirect) => {
    if (braintree && $(braintreeDropin).length) {
      const totalPriceInDollars = (totalPriceInCents / 100).toFixed(2);
      const braintreeConf = braintreeDropinConfig(totalPriceInDollars, braintreeDropin);

      braintree.dropin.create(braintreeConf,
        function (createErr, instance) {
        braintreeDropinInstance = instance;
          if (createErr) {
            // An error in the create call is likely due to
            // incorrect configuration values or network issues.
            // An appropriate error will be shown in the UI.

            $subscriptionError.html(
              `${createErr.message}
               <br>${CUSTOM_MESSAGE.BRAINTREE_CREATE_ERROR}`
            );
            $subscriptionError.show();
            toggleButtonLoading($goToPaymentBtn, false);
            // eslint-disable-next-line no-console
            console.error('dropin create error: ', createErr);
            return;
          }

          toggleButtonLoading($backToPlansBtn, false);

          if (instance.isPaymentMethodRequestable()) {
            // This will be true if you generated the client token
            // with a customer ID and there is a saved payment method
            // available to tokenize with that customer.
            $submitPaymentBtn.show();
          }

          instance.on('paymentMethodRequestable', () => {
            $submitPaymentBtn.show();
            $subscriptionError.hide();
          });

          instance.on('noPaymentMethodRequestable', () => {
            $submitPaymentBtn.hide();
            $subscriptionError.hide();
          });

          instance.on('paymentOptionSelected', () => {
            $subscriptionError.hide();
            $submitPaymentBtn.show();
          });

          toggleButtonLoading($goToPaymentBtn, false);
          toggleButtonLoading($submitPaymentBtn, false);

          $submitPaymentBtn.click(() => {
            $backToPlansBtn.hide();
            $subscriptionError.hide();
            toggleButtonLoading($submitPaymentBtn, true);

            const priceFor3DSecureStr = (priceFor3DSecure / 100).toFixed(2).toString();
            const threeDSecureConf = threeDSecureConfig(priceFor3DSecureStr);

            instance.requestPaymentMethod({
              threeDSecure: threeDSecureConf
            }, function (requestPaymentMethodErr, payload) {
              if (requestPaymentMethodErr) {
                // eslint-disable-next-line no-console
                console.error('requestPaymentMethodErr: ', requestPaymentMethodErr);

                $subscriptionError.text(requestPaymentMethodErr.message);
                $subscriptionError.show();
                toggleButtonLoading($submitPaymentBtn, false);
                $backToPlansBtn.show();
                return;
              }

              if (isThreeDSecureCanceled(payload)) {
                instance.clearSelectedPaymentMethod();
                $subscriptionError.text(CUSTOM_MESSAGE.THREE_D_SECURE_ABORT);
                $subscriptionError.show();
                $backToPlansBtn.show();
                toggleButtonLoading($submitPaymentBtn, false);
                return;
              }

              $.ajax({
                url: ENDPOINT.BUY_SUBSCRIPTION,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify({
                  payment_method_nonce: payload.nonce,
                  plan_slug: slug,
                  source: 'blog-offer'
                })
              })
                .then((response) => {
                  const callback = () => {
                    $purchaseContainer.hide();

                    $websitePlansModalTitle.html(successModalTitle);
                    $buySubscriptionSuccessStep.show();

                    isSuccessStepShown = true;
                    ga('send', 'event', 'Monetization3_Pay_OK', 'PopUp_Shown', 'Load');

                    deleteUrlParam(GET_PARAMS.DOWNLOAD_POPUP_LIMIT);
                  };

                  ga('send', 'event', 'Worksheets_Pay_Acc', 'Subscribe_Button', 'Click');
                  triggerAnalyticsOnCheckout(response, slug, callback); // TODO

                  const actionType = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_ACTION, true);
                  const worksheetId = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_ID, true);
                  const worksheetSlug = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_SLUG, true);

                  switch (actionType) {
                    case WORKSHEET_ACTIONS.MARK_AS_DONE:
                      toggleMarkWorksheetAsDone(worksheetId, true);
                      break;
                    case WORKSHEET_ACTIONS.ADD_TO_FAVORITE:
                      toggleAddWorksheetToFavorite(worksheetId, true);
                      break;
                    case WORKSHEET_ACTIONS.INIT_WORKSHEET_DOWNLOAD:
                      toggleMarkWorksheetAsDone(worksheetId, true);
                      dispatchCustomEvent(WEBSITE_CUSTOM_EVENTS.DOWNLOAD_WORKSHEET, {worksheetId});
                      break;
                    case WORKSHEET_ACTIONS.GO_TO_WORKSHEET_CHECK_IN_TGC:
                      const goToOnlineWorksheet = () => {
                        toggleMarkWorksheetAsDone(worksheetId, true, () => {
                          toggleMarkWorksheetAsDone(worksheetId, true);
                        });
                        redirectToCompleteOnlineInTGC(worksheetSlug);
                      };

                      handleCompleteOnline(goToOnlineWorksheet);
                      break;
                  }

                  deleteGetParamFromUrl(GET_PARAMS.DOWNLOAD_POPUP_LIMIT);

                  if(redirect) window.location = redirect;
                }, (errorResp) => {
                  instance.clearSelectedPaymentMethod();
                  $subscriptionError.text('');
                  $subscriptionError.append(getError(errorResp));
                  $subscriptionError.show();
                  $backToPlansBtn.show();

                  toggleButtonLoading($submitPaymentBtn, false);
                });
            });
          });
        });
    }
  };

  const isTrialEnabled = ($button) => {
    if (!window.ableToGetTrial) return false;

    const trialDuration = $button.attr(trialDurationAttr);
    const isTrialDisabled = parseInt(trialDuration) === 0;
    if (isTrialDisabled) return false;

    return true;
  };

  const getTotalPriceInCents = ($button) => {
    const priceInCents = $button.attr(priceAttr);
    let totalPriceInCents = priceInCents;
    const priceFor3DSecure = priceInCents;

    if (isTrialEnabled($button)) totalPriceInCents = 0;

    return {totalPriceInCents, priceFor3DSecure};
  };

  const goToPaymentErrorHandler = ($button) => {
    toastr.error('Something went wrong, please contact us!', '', {timeOut: 3000});
    toggleButtonLoading($button, false);
  };

  const dropinTokenCallback = (planPrice, planSlug, $button) => {
    if (!window.braintree) {
      goToPaymentErrorHandler($button);
      return;
    }

    const redirect = $button.attr(redirectAttr);

    renderSecondStepUI($button);

    const {totalPriceInCents, priceFor3DSecure} = getTotalPriceInCents($button);
    initBraintreeDropin(totalPriceInCents, priceFor3DSecure, planSlug, redirect);
  };

  const onDismissPlansModal = () => {
    const actionType = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_ACTION, true);
    const worksheetId = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_ID, true);

    switch (actionType) {
      case WORKSHEET_ACTIONS.MARK_AS_DONE:
        toggleMarkWorksheetAsDone(worksheetId, true);
        break;
      case WORKSHEET_ACTIONS.ADD_TO_FAVORITE:
        toggleAddWorksheetToFavorite(worksheetId, true);
        break;
      case WORKSHEET_ACTIONS.INIT_WORKSHEET_DOWNLOAD:
        toggleMarkWorksheetAsDone(worksheetId, true);
        dispatchCustomEvent(WEBSITE_CUSTOM_EVENTS.DOWNLOAD_WORKSHEET, {worksheetId});
        break;
    }

    $websitePlansModal.modal('hide');
  };

  $(document)
    .on('click', freePlanBtn, () => {
      if ($(adFreeSubscription).length) {
        ga('send', 'event', 'Add_free_subscriptions', 'Limited_access', 'Click');

        if (document.referrer) window.location = document.referrer;
        window.location = window.location.origin;
        return;
      }

      onDismissPlansModal();
      ga('send', 'event', 'Monetization3_Three_Plans', 'Free_Plan', 'Click');
    })
    .on('click', backToPlansBtn, () => {
      $plansContainer.show();
      $submitPaymentBtn.hide();
      $purchaseContainer.hide();
      $subscriptionError.hide();

      braintreeDropinInstance.teardown((err) => {
        // eslint-disable-next-line no-console
        if (err) { console.error('An error occurred during teardown:', err); }
      });
    })
    .on('click', planBtn, (e) => {
      const $button = $(e.target);
      const planPrice = $button.attr(priceAttr);
      const planSlug = $button.attr(slugAttr);

      toggleButtonLoading($button, true);
      toggleButtonLoading($backToPlansBtn, true);

      if (!$(adFreeSubscription).length) {
        if (isTwoPlansModal) {
          if (planPrice === '10000') {
            ga('send', 'event', 'Monetization3_Two_Plans', 'Plan_10', 'Click');
          } else {
            ga('send', 'event', 'Monetization3_Two_Plans', 'Plan_1_99', 'Click');
          }
        } else {
          if (planPrice === '10000') {
            ga('send', 'event', 'Monetization3_Three_Plans', 'Plan_10', 'Click');
          } else {
            ga('send', 'event', 'Monetization3_Three_Plans', 'Plan_1_99', 'Click');
          }
        }
      }

      if (window.isLoggedIn) {
        $.getScript(
          DROPIN_SCRIPT_URL,
          // eslint-disable-next-line max-len
          handleDropinToken(() => {dropinTokenCallback(planPrice, planSlug, $button);}, goToPaymentErrorHandler)
        );
      } else {
        $.ajax({
          url: ENDPOINT.USER_PROFILE,
          contentType: 'application/json',
          type: 'POST',
          data: JSON.stringify({
            account_type: ACCOUNT_TYPES.PARENT,
            email: null,
            need_to_login: true,
            source: getUserSources('source'),
            medium: getUserSources('medium'),
            campaign: getUserSources('campaign'),
            placement: getUserSources('placement')
          })
        }).then(() => {
          window.isLoggedIn = true;

          $.getScript(
            DROPIN_SCRIPT_URL,
            // eslint-disable-next-line max-len
            handleDropinToken(() => {dropinTokenCallback(planPrice, planSlug, $button);}, goToPaymentErrorHandler)
          );
        }, (error) => {
          toggleButtonLoading($button, false);
          toggleButtonLoading($backToPlansBtn, false);
          toastr.error(getError(error), '', {timeOut: 3000});
        });
      }
    });

    $(MODALS.WEBSITE_PLANS_MODAL).on('hidden.bs.modal', () => {
      onDismissPlansModal();
      $websitePlansModalTitle.html(defaultModalTitle);
      $plansContainer.removeClass(twoPlansClassName);
      $subtitle.hide();

      if (isTwoPlansModal) {
        ga('send', 'event', 'Monetization3_Two_Plans', 'Close', 'Click');
      } else {
        ga('send', 'event', 'Monetization3_Three_Plans', 'Close', 'Click');
      }

      if (isSuccessStepShown && window.isSignUpComplete === false) {
        $('[data-selector="learning-resources-registration-title"]').html('Complete sign-up!');
        $('[data-selector="learning-resources-registration-login-btns"]').hide();
        $('[data-selector="social-btn-login"]').hide();
        $(MODALS.WEBSITE_SIGN_UP_MODAL).modal('show');
        return;
      }

      if (isSuccessStepShown) {
        document.location.reload();
      }
    });

    onPageLoad();
});
