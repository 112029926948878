import toastr from 'toastr/toastr';

import {
  toggleButtonLoading,
  storage
} from '../utils';

import {
  ENDPOINT,
  SUBSCRIPTION_POPUP,
  LOCAL_STORAGE_KEYS,
} from '../config';

$(document).ready( () => {

  // if (window.isLoggedIn) return;
  // if (storage.get(LOCAL_STORAGE_KEYS.IS_BLOG_SUBSCRIBED, 'true')) return;

  const $blogSubscriptionPopup = $('[data-selector="blog-subscription-popup"]');
  if (!$blogSubscriptionPopup.length) return;

  const $blogSubscriptionPopupSubscribe = $('[data-selector="blog-subscription-popup-subscribe"]');
  const $blogSubscriptionPopupSuccess = $('[data-selector="blog-subscription-popup-success"]');
  const blogSubscriptionPopupSubmit = '[data-selector="blog-subscription-popup-submit"]';
  const $blogSubscriptionPopupSubmit = $(blogSubscriptionPopupSubmit);
  const $blogSubscriptionPopupForm = $('[data-selector="blog-subscription-popup-form"]');
  const $blogSubscriptionPopupInput = $('[data-selector="blog-subscription-popup-input"]');

  const currentDate = () => {
    const d = new Date();
    const currDate = d.getDate();
    let currMonth = d.getMonth() + 1;
    if (currMonth < 10) currMonth = '0' + currMonth;
    const currYear = d.getFullYear();

    return `${currYear}-${currMonth}-${currDate}`;
  };

  const setPopupDisplayParams = () => {
    const popupLastDisplayDate = storage.get(LOCAL_STORAGE_KEYS.POPUP_LAST_DISPLAY_DATE);
    const isPopupDisplayRepeat = popupLastDisplayDate === currentDate();

    if (isPopupDisplayRepeat) {
      const popupDisplayCounter =
        storage.get(LOCAL_STORAGE_KEYS.SUBSCRIPTION_POPUP_DISPLAY_COUNTER);
      const isPopupDisplayLimitReached = popupDisplayCounter >= SUBSCRIPTION_POPUP.DISPLAY_LIMIT;

      if (isPopupDisplayLimitReached) {
        return;
      } else {
        showPopup(SUBSCRIPTION_POPUP.SECOND_DISPLAY_DELAY, popupDisplayCounter);
      }
    } else {
      showPopup(SUBSCRIPTION_POPUP.DEFAULT_DELAY);
    }
  };

  const showPopup = (delay, counter = 0) => {
    setTimeout( () => {
      $blogSubscriptionPopup.modal('show');

      storage.set(LOCAL_STORAGE_KEYS.POPUP_LAST_DISPLAY_DATE, currentDate());
      storage.set(LOCAL_STORAGE_KEYS.SUBSCRIPTION_POPUP_DISPLAY_COUNTER, ++counter);
    }, delay);
  };

  const submitSubscriptionForm = () => {
    const modalType = $($blogSubscriptionPopup).attr('data-modal-type');
    const blogSubscriptionPopupFormParsley = $blogSubscriptionPopupForm.parsley();

    if (!blogSubscriptionPopupFormParsley.isValid()) {
      blogSubscriptionPopupFormParsley.validate();
      return;
    }

    ga('send', 'event', 'Blog_New_Subscriber', 'Subscribe Button', 'Click');

    toggleButtonLoading($blogSubscriptionPopupSubmit, true);
    const subscribeEmail = $blogSubscriptionPopupInput.val().trim();

    $.ajax({
      url: ENDPOINT.USER_SUBSCRIBE_V1,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        email_address: subscribeEmail,
        campaign: modalType === 'early_access' ? 'Early Access' : 'Blog',
        // REF_LANDIN: window.location.href
      })
    })
      .then(() => {
        storage.set(LOCAL_STORAGE_KEYS.IS_BLOG_SUBSCRIBED, 'true');
        toggleButtonLoading($blogSubscriptionPopupSubmit, false);
        $blogSubscriptionPopupSubscribe.hide();
        $blogSubscriptionPopupSuccess.show();
      }, () => {
        toggleButtonLoading($blogSubscriptionPopupSubmit, false);
        $blogSubscriptionPopup.modal('hide');

        // TODO add support for spanish lang
        toastr.warning('Something went wrong. Please contact us.');
      });
  };

  setPopupDisplayParams();

  $(document)
    .on('click', blogSubscriptionPopupSubmit, (e) => {
      e.preventDefault();
      submitSubscriptionForm();
    });
});
