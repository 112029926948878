import {
  MODALS,
  MIDDLE_WIDTH,
  // SHOW_ADDS_FREE_MODAL_DELAY
} from '../config';
const $document = $(document);

$document.ready(function () {
  const $header = $('[data-selector="header"]');
  if (!$header.length) return;

  const addsFreeHeaderBtn = '[data-selector="add-free-header-btn"]';
  const addsFreeModalBtn = '[data-selector="add-free-modal-btn"]';
  const headerAppDropdownToggleBtn = '[data-selector="app-dropdown-toggle"]';
  const headerAppDropdownArrow = '[data-selector="header-app-dropdown-arrow"]';
  const headerDropdownList = '[data-selector="header-dropdown-list"]';
  const mainMenuDropdown = '[data-selector="main-menu-dropdown"]';
  const authorizationRequiredLink = '[data-selector="authorization-required-link"]';
  const startFreeBtn = '[data-selector="sign-up-modal-open-button"]';

  const appDropdown = '[data-selector="app-dropdown"]';
  const appDropdownMenu = '[data-selector="app-dropdown-menu"]';
  let addsFreeModalBtnClicked = false;

  const handleShowEarlyAccesModal = () => {
    $('#subscriptionPopUp').attr('data-modal-type', 'early_access');
    $('[data-selector="blog-subscription-popup-title"]').html('CoachEye.AI Early Access Registration Form');
    $('[data-selector="blog-subscription-popup-text"]').html('Leave your email to get early access to CoachEye.AI services.');
    $('#subscriptionPopUp').modal('show');

    $('#subscriptionPopUp').on('hidden.bs.modal', function () {
      $('[data-selector="blog-subscription-popup-title"]').html('Subscribe to CoachEye.AI News and Articles!');
      $('[data-selector="blog-subscription-popup-text"]').html('Get updates on the latest posts, new and expert’s opinions from CoachEye.AI.');
    });
  };

  $document
    .on('click', startFreeBtn, () => { handleShowEarlyAccesModal();})
    .on('click', headerAppDropdownToggleBtn, (e) => {
      e.stopPropagation();
      e.preventDefault();

      if (window.innerWidth <= MIDDLE_WIDTH) {
        const $btn = $(e.currentTarget);
        $btn.find(headerAppDropdownArrow).toggleClass('_closed');

        // toggle slide apps menu
        const $appDropdownMenu = $btn.closest(appDropdown).find(appDropdownMenu);
        $appDropdownMenu.slideToggle();

        if (!$appDropdownMenu.length) {
          const $headerDropdownList = $btn.closest(mainMenuDropdown).find(headerDropdownList);
          $headerDropdownList.slideToggle();
        }
      }
    })
    .on('click', addsFreeHeaderBtn, () => {
      ga('send', 'event', 'Ad-Free Click', 'Ad-Free Click', 'Click');
      $(MODALS.WEBSITE_PLANS_MODAL).modal('show');
    })
    .on('click', addsFreeModalBtn, () => {
      addsFreeModalBtnClicked = true;
      ga('send', 'event', 'Adsence_PopUp', 'Ad-Free Click', 'Click');
      $(MODALS.ADDS_FREE).modal('hide');
    })
    .on('click', authorizationRequiredLink, (e) => {
      if(!window.isLoggedIn) {
        e.preventDefault();
        $('#logInModal').modal('show');
      }
    });

  $(MODALS.ADDS_FREE).on('hidden.bs.modal', () => {
    if (!addsFreeModalBtnClicked) return;
    $(MODALS.WEBSITE_PLANS_MODAL).modal('show');
  });

  // setTimeout(() => {
  //   const currentUrl = $('[data-url]').attr('data-url');
  //   if (currentUrl !== 'sitemap') return;
  //
  //   $(MODALS.ADDS_FREE).modal('show');
  //   ga('send', 'event', 'Adsence_PopUp', 'Show', 'Load');
  // }, SHOW_ADDS_FREE_MODAL_DELAY);
});
