import {
  initGoogleRecaptcha,
  toggleButtonLoading,
  getUserSources,
  storage
} from '../utils';
import {
  ACCOUNT_TYPES,
  ENDPOINT,
  LOCAL_STORAGE_KEYS,
  MODALS
} from '../config';


$(document).ready(function () {
  const termsCheckbox = '[data-selector="terms-condition-checkbox"]';
  const formSubmitBtn = '[data-selector="modal-registration-form-submit-btn"]';
  const $formError = $('[data-selector="modal-registration-form-submit-error"]');
  const learningResourcesModal = '#learning-resources-registration';
  const $registrationModalSubmitBtn = $(formSubmitBtn);
  const $termsCheckbox = $(termsCheckbox);
  const modalRegistrationFormEmailInput = '[data-selector="modal-registration-form-email-input"]';
  const websiteSignUpPswInput = '[data-selector="modal-registration-form-password-input"]';
  const loginSpecialInput = '[data-selector="login-special-input"]';
  const modalRegistrationForm = '[data-selector="modal-registration-form"]';

  let googleCaptchaToken = null;

  const onRecaptchaExpire = () => {
    googleCaptchaToken = null;
  };

  const getPayload = () => {
    const email = $(modalRegistrationFormEmailInput).val();
    const password = $(websiteSignUpPswInput).val();
    const placement = storage.get(LOCAL_STORAGE_KEYS.PLACEMENT, true);

    // TODO add captcha data to payload

    const payload = {
      account_type: ACCOUNT_TYPES.PARENT,
      email: email,
      password: password,
      source: getUserSources('source'),
      medium: getUserSources('medium'),
      campaign: getUserSources('campaign'),
      placement: placement ? placement : ''
    };

    const websiteAccountType = storage.get(LOCAL_STORAGE_KEYS.WEBSITE_ACCOUNT_TYPE, true);
    if (websiteAccountType) payload.website_account_type = websiteAccountType;

    return payload;
  };

  const loadRecaptcha = (isChecked) => {
    initGoogleRecaptcha((token) => {
      $formError.hide();
      ga('send', 'event', 'Registration_flow', 'Capcha', 'step3');
      googleCaptchaToken = token;
      if (isChecked) {
        $(formSubmitBtn).attr('disabled', !isChecked);
      }
    }, onRecaptchaExpire);
  };

  $(document)
    // submit blog sign up modal
    .on('click', formSubmitBtn, function (e) {
      e.preventDefault();

      const $regForm = $(e.target).closest(learningResourcesModal).find(modalRegistrationForm);
      const hiddenInputVal = $regForm.find(loginSpecialInput).val();
      if (hiddenInputVal) return;

      if (!googleCaptchaToken) {
        $formError.html('Please complete the CAPTCHA');
        $formError.show();
        return;
      }

      const parsleyForm = $regForm.parsley();

      if (!parsleyForm.isValid() || !$termsCheckbox.is(':checked')) {
        parsleyForm.validate();
        return;
      }

      ga('send', 'event', 'Registration', 'EmailWorksheets', 'Parent');
      toggleButtonLoading($registrationModalSubmitBtn, true);

      $.ajax({
        url: ENDPOINT.USER_PROFILE,
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify(getPayload())
      }).then(() => {
          storage.set(LOCAL_STORAGE_KEYS.CHECK_AVAILABILITY_OF_PDF, true);
          $(MODALS.WEBSITE_SIGN_UP_MODAL).modal('hide');
          // we need to reload the page to show correct user interface (user is logged in)
          document.location.reload(true);
        }, (errorResponse) => {
          toggleButtonLoading($registrationModalSubmitBtn, false);
          const error = JSON.parse(errorResponse.responseText);
          $formError.html(error.error);
          $formError.show();
        });
    })
    .on('change', termsCheckbox, function () {
      const isChecked = $(this).is(':checked');
      if (googleCaptchaToken) {$(formSubmitBtn).attr('disabled', !isChecked);}
      loadRecaptcha(isChecked);
    });
});
