import {
  CURRENCY,
  CUSTOM_MESSAGE,
  ENDPOINT,
  GET_PARAMS,
  URLS,
  EBLOX_COUPONS,
  ACCOUNT_TYPES,
  DROPIN_SCRIPT_URL,
  SUBSCRIPTION_PERIOD_MAPPING,
  SUBSCRIPTION_PERIODS,
  LOCAL_STORAGE_KEYS
} from '../config';
import {
  toggleButtonLoading,
  getError,
  braintreeDropinConfig,
  threeDSecureConfig,
  getUrlParam,
  capitalize,
  scrollToHashedElement,
  getUserSources,
  isFormValid,
  isThreeDSecureCanceled,
  storage,
  toggleStepProgressBar
} from '../utils';
import {
  doCheckIsCouponDistributorCode,
  toggleApplyCouponBtn,
  toggleCouponBlock,
  getSelectedOfferDetails,
  getCurrencyPrice,
  getTotalPricesInCents,
  handleDropinToken,
  setCheckoutBtnTitle,
  getShippingPriceInCents,
  defineTrialMessagePlural,
  setDefaultOfferSubmitText
} from './subscription.utils';


$(document).ready(() => {
  const $subscriptionPlans = $('[data-selector="subscription-plans"]');
  if (!$subscriptionPlans.length) return;

  const subscriptionPlansHash = '#subscription-plans';
  const braintreeDropin = '#subscription-plans-dropin-container';
  const hiddenInputSelect = '[data-selector="buy-parent-subscription-special-input"]';

  const couponBlockParams = {
    activeClass: '_active',
    emptyCouponClass: '_empty',
    showCouponText: 'Enter coupon code',
    hideCouponText: 'Hide coupon code'
  };

  // page title
  const $pageTitle = $('[data-selector="subscription-plans-main-title"]');
  const firstStepMainTitle = $pageTitle.html();
  const secondStepNoTrialTitle = 'Start learning now!';

  const $appInfoSection = $('[data-selector="app-info-section"]');
  const $ebloxInfoSection = $('[data-selector="eblox-info-section"]');
  const $reviewsSection = $('[data-selector="subscription-plans-reviews-section"]');
  const $subscriptionError = $('[data-selector="subscription-error"]');
  const $buttonsBlockWrapper = $('[data-selector="buttons-block-wrapper"]');
  const $submitPaymentBtn = $('[data-selector="submit-payment-btn"]');

  const firstStepClassName = '_first-section';

  // plan attributes
  const planAttr = {
    isTrialRadioEnabled: 'data-is-trial-radio-enabled',
    slug: 'data-slug',
    trialDuration: 'data-trial-duration',
    offerTitle: 'data-offer-title',
    offerPeriod: 'data-offer-period',
    price: 'data-price',
    backUrl: 'data-offer-back-url',
    offerPeriodDisplay: 'data-offer-period-display',
    submitText: 'data-offer-submit',
    isCouponEnabled: 'data-is-coupon-enabled',
    isOfferDefault: 'data-is-offer-default',
    showOnboardingSteps: 'data-show-onboarding-steps'
  };
  const checkoutTextAttr = {
    trial: 'data-trial-text',
    noTrial: 'data-no-trial-text'
  };

  // selected plan block (second step)
  const planInput = '[data-selector="subscription-plan-input"]';
  const selectedPlanInput = `${planInput}:checked`;
  const secondStepClassName = '_second-step';
  const prevSectionButton = '[data-selector="back-to-prev-section-btn"]';
  const $prevSectionButton = $(prevSectionButton);
  const goToPaymentStepBtn = '[data-selector="go-to-checkout-btn"]';
  const $goToPaymentStepBtn = $(goToPaymentStepBtn);
  const $stepProgressBar = $('[data-selector="step-progress-bar"]');
  const planInputWrapperSelector = '[data-selector="subscription-plan-input-wrapper"]';

  // subscription details elements
  const $planPeriod = $('[data-selector="plan-period-value"]');
  const $planFullPrice = $('[data-selector="plan-full-price-value"]');
  const $planTrialPeriod = $('[data-selector="plan-trial-period-value"]');
  const $planDiscountRow = $('[data-selector="plan-discount-row"]');
  const $planTrialRow = $('[data-selector="plan-trial-row"]');
  const $planDiscount = $('[data-selector="plan-discount-value"]');
  const $planTotalPrice = $('[data-selector="plan-total-price-value"]');
  const $planRenewCondition = $('[data-selector="plan-renew-condition"]');
  const $trialText = $('[data-selector="trial-text"]');

  let removedSubscriptions = [];

  //e-blox addon
  const $addonOnPaymentStep = $('[data-selector="addon-on-payment-step"]');

  let isEbloxAdded = false;
  const $ebloxAddonCheckbox = $('[data-selector="subscription-plans-eblox-addon-checkbox"]');
  const ebloxAddonPaymentStepCheckbox = '[data-selector="eblox-addon-checkbox-payment-step"]';

  const $ebloxRow = $('[data-selector="subscription-plans-eblox-row"]');
  const $ebloxPrice = $('[data-selector="eblox-info-value"]');
  const $shippingRow = $('[data-selector="subscription-plans-shipping-row"]');
  const $shippingPrice = $('[data-selector="shipping-info-value"]');

  const $shippingForm = $('[data-selector="shipping-addon-form"]');
  const $shippingFormItem = $('[data-selector="shipping-addon-form-item"]');
  const shippingCountriesSelect = '[data-selector="shipping-countries-select"]';
  const ebloxTooltipLink = '[data-selector="subscription-plans-tooltip-link"]';
  const ebloxInfoSectionHash = '#eblox-info-section';

  // coupon
  const $couponInputSection = $('[data-selector="coupon-input-section"]');
  const $couponBlock = $('[data-selector="coupon-block"]');
  let activeCoupon = null;
  let amountOff = 0;
  const toggleCouponBlockBtn = '[data-selector="toggle-coupon-block-btn"]';
  const $toggleCouponBlockBtn = $(toggleCouponBlockBtn);
  const couponForm = '[data-selector="coupon-form"]';
  const $couponForm = $(couponForm);
  const $couponError = $('[data-selector="coupon-error"]');
  const couponInput = '[data-selector="enter-coupon-input"]';
  const $couponInput = $couponForm.find(couponInput);
  const applyCoupon = '[data-selector="apply-coupon-btn"]';
  const $applyCoupon = $couponForm.find(applyCoupon);
  const removeCoupon = '[data-selector="remove-coupon-btn"]';
  const $removeCoupon = $couponForm.find(removeCoupon);
  const $couponMessage = $couponForm.find('[data-selector="coupon-message"]');
  const $licenseActivationCodeModal = $('#licenseActivationCode');
  let isSpecialCouponApplied = false;
  let specialCouponTrialDuration = null;

  const $trialNoteText = $('[data-selector="subscription-trial-text"]');

  const firstSection = $('#1-step-sample');
  const secondSection = $('#2-step-sample');
  const section = {
    first: 0,
    second: 1
  };

  const isTrialActiveRadio = '[data-selector="is-trial-active-radio"]';
  const $isTrialActiveRadio = $(isTrialActiveRadio);
  const $isTrialActiveRadioLabel = $('[data-selector="is-trial-active-radio-label"]');
  const $isTrialActiveRadioWrapper = $('[data-selector="is-trial-active-radio-wrapper"]');

  const $noteForm = $('[data-selector="subscription-plans-note-form"]');
  const $noteFormInput = $('[data-selector="subscription-plans-note-form-input"]');
  let parsleyNoteForm;
  let isEbloxNoteInputSeen = false;

  const $hasCommentsCheck = $('[data-selector="has-comments-check"]');
  const $subscriptionReviews = $('[data-selector="subscription-reviews"]');
  const $reviewsCarousel = $('[data-selector="subscription-plans-reviews-slider"]');
  const $reviewsSliderLoader = $('[data-selector="subscription-plans-sample-reviews-loader"]');

  let dropinInstance;

  const doCheckForPreselectedPlan = () => {
    if ($(selectedPlanInput).length) return;
    $(planInput).first().prop('checked', true);
  };

  const setStyleSelectedPlan = () => {
    $(planInput).each((index, plan) => {
      const $planInputWrapper = $(plan).closest(planInputWrapperSelector);
      if ($planInputWrapper.hasClass('_checked')) {
        $planInputWrapper.removeClass('_checked');
      }
    });

    $(selectedPlanInput).closest(planInputWrapperSelector).addClass('_checked');
  };

  const initFirstSectionUI = () => {
    toggleIsTrialSelectRadio();
    setTrialDurationToTrialRadioBtnLabel();
    setDefaultOfferSubmitText($goToPaymentStepBtn, $(selectedPlanInput).attr(planAttr.submitText));
    toggleTrialNoteText();
    initTooltipLinkVisibility();
    applyProvidedCoupon();
    toggleIsCouponInputSection();
    toggleApplyCouponBtn($couponInput, $applyCoupon, couponBlockParams);
    setStyleSelectedPlan();
    initReviewSlider().then($reviewsCarousel.slick('refresh'));
  };

  const toggleIsCouponInputSection = () => {
    const isCouponInputAvailable = $(selectedPlanInput).attr(planAttr.isCouponEnabled) === 'True';

    isCouponInputAvailable ? $couponInputSection.show() : $couponInputSection.hide();
  };

  const triggerGAEventOnCheckout = () => {
    if (isTrialActive()) {
      ga('send', 'event', 'Trial', 'Started', 'Parent');
      if (isEbloxAdded) ga('send', 'event', 'Eblox', 'Started', 'Parent');
      return;
    }

    ga('send', 'event', 'Subscription', 'Started', 'Parent');
  };

  const triggerGAEventOnGoToPayment = (offerPeriod) => {
    if (typeof offerPeriod === 'string' && offerPeriod.length) {
      const formattedOfferPeriod = offerPeriod.replace(/^./, offerPeriod[0].toUpperCase());
      ga('send', 'event', 'Subscription', 'Initiated', formattedOfferPeriod);
    }
  };

  const triggerAnalyticsOnCheckout = (response, slug, callback = () => {}) => {
    callback();
  };

  const setTrialDurationToTrialRadioBtnLabel = () => {
    const trialDuration = $(selectedPlanInput).attr(planAttr.trialDuration);
    const trialMessagePlural = defineTrialMessagePlural(trialDuration, 'day');
    const labelText = `Enable ${trialMessagePlural} free trial`;
    $isTrialActiveRadioLabel.text(labelText);

    $trialText.html(getTrialDurationText());
  };

  const isTrialActive = () => {
    if (!window.ableToGetTrial) return false;

    if (isSpecialCouponApplied) return true;

    const trialDuration = $(selectedPlanInput).attr(planAttr.trialDuration);
    const isTrialDisabled = parseInt(trialDuration) === 0;
    if (isTrialDisabled) return false;

    if (activeCoupon) return false;

    if ($isTrialActiveRadio.length) {
      const isTrialEnabled = $isTrialActiveRadio.is(':checked');
      const isTrialRadioEnabled = $(selectedPlanInput).attr(planAttr.isTrialRadioEnabled);
      if (isTrialRadioEnabled === 'False' && !isTrialEnabled) return true;

      return isTrialEnabled;
    }

    return true;
  };

  const getPurchaseStepTitle = () => {
    if (isTrialActive()) {
      let trialDuration = $(selectedPlanInput).attr(planAttr.trialDuration);

      if (isSpecialCouponApplied && specialCouponTrialDuration) {
        trialDuration = specialCouponTrialDuration;
      }

      if (trialDuration === '0') {
        return secondStepNoTrialTitle;
      }

      const trialMessagePlural = defineTrialMessagePlural(trialDuration, 'day');
      const secondStepTrialTitle = `Start your ${trialMessagePlural} Free Trial Now!`;
      return secondStepTrialTitle;
    }

    return secondStepNoTrialTitle;
  };

  const toggleTrialNoteText = () => {
    if (isTrialActive()) {
      $trialNoteText.show();
      return;
    }

    $trialNoteText.hide();
  };

  const getTrialDurationText = () => {
    const noTrialText = 'Start learning!';

    if (isTrialActive()) {
      let trialDuration = $(selectedPlanInput).attr(planAttr.trialDuration);

      if (isSpecialCouponApplied && specialCouponTrialDuration && checkCouponApplicability()) {
        trialDuration = specialCouponTrialDuration;
      }

      if (parseInt(trialDuration) === 0 ) {
        return noTrialText;
      }

      const trialMessagePlural = defineTrialMessagePlural(trialDuration, 'day');
      const trialDurationText = `${trialMessagePlural} free trial!`;
      return trialDurationText;
    }

    return noTrialText;
  };

  const checkCouponApplicability = () => {
    const planSlug = $(selectedPlanInput).attr(planAttr.slug);
    const itemIndex = removedSubscriptions.indexOf(planSlug);
    const isInvalid = removedSubscriptions[itemIndex];

    if (isInvalid) {
      $goToPaymentStepBtn.prop('disabled', true);
      $couponError.show();
    } else {
      $goToPaymentStepBtn.prop('disabled', false);
      $couponError.hide();
    }

    return !isInvalid;
  };

  const setSelectedPlanDataToUI = (
    planPeriod,
    planFullPrice,
    planTrialPeriod,
    planFormattedNewPrice,
    planPeriodDisplay
  ) => {
    $planPeriod.text(capitalize(planPeriodDisplay));
    $planFullPrice.text(`${CURRENCY}${planFullPrice}`);

    if (activeCoupon && amountOff > 0) {
      $planDiscount.text(`${CURRENCY}${amountOff}`);
      $planDiscountRow.show();
    }

    if (isTrialActive()) {
      $planTrialPeriod.text(`${planTrialPeriod} days`);
      $planTrialRow.show();
      $planTotalPrice.text(`${CURRENCY}0.00`);

      if (planPeriod !== SUBSCRIPTION_PERIODS.LIFETIME) {
        const text = SUBSCRIPTION_PERIOD_MAPPING[planPeriod](planFullPrice);
        $planRenewCondition.html(text);
      }

      $planRenewCondition.show();
    } else {
      $planTotalPrice.text(`${CURRENCY}${planFormattedNewPrice}`);
    }

    isEbloxAdded = $ebloxAddonCheckbox.is(':checked');
    if (isEbloxAdded) {
      addEbloxDataUItoPaymentStep();
    } else {
      removeEbloxDataUIFromPaymentStep();
    }
  };

  const getFirstStepMainTitle = () => {
    $(planInput).each((index, plan) => {
      if ($(plan).attr(planAttr.isOfferDefault) === 'True') {
        return $(plan).attr(planAttr.offerTitle);
      }
    });

    return $(planInput).first().attr(planAttr.offerTitle);
  };

  const setMainTitle = (sectionId) => {
    if (sectionId === undefined) return null;

    switch (sectionId) {
      case section.first:
        $pageTitle.html(getFirstStepMainTitle() || firstStepMainTitle);
        break;
      case section.second:
        $pageTitle.html(getPurchaseStepTitle());
        break;
    }
  };

  const prepareFirstSectionUI = () => {
    $hasCommentsCheck.addClass('_has-comments');
    $subscriptionReviews.show();
    $subscriptionPlans.addClass(firstStepClassName);
    secondSection.hide();
    firstSection.show();
    $subscriptionPlans.removeClass(secondStepClassName);
    $appInfoSection.show();
    $reviewsSection.show();
    $ebloxInfoSection.show();
    $subscriptionError.hide();
    $(braintreeDropin).text('');
    $planRenewCondition.text('');
    initReviewSlider().then($reviewsCarousel.slick('refresh'));
  };

  const prepareSecondSectionUI = () => {
    $hasCommentsCheck.removeClass('_has-comments');
    $subscriptionReviews.hide();
    $subscriptionPlans.removeClass(firstStepClassName);
    $subscriptionPlans.addClass(secondStepClassName);
    firstSection.hide();
    $appInfoSection.hide();
    $ebloxInfoSection.hide();
    $reviewsSection.hide();
    $planDiscountRow.hide();
    $planTrialRow.hide();
    $planRenewCondition.hide();
    secondSection.show();

    handleProgressBar();
  };

  const selectSection = (sectionId) => {
    if (sectionId === section.first) {
      prepareFirstSectionUI();
      // Remove an event listener set by braintree-creating function so as
      // to prevent creating multiple event handlers in case of function call repeat
      $submitPaymentBtn.off('click');
      return;
    }

    if (sectionId === section.second) {
      toggleButtonLoading($prevSectionButton, true);
      prepareSecondSectionUI();
      setCheckoutBtnTitle($submitPaymentBtn, isTrialActive(), checkoutTextAttr);
    }
  };

  /**
   * returns is trial status available to be changed by user
   *
   * @returns {boolean}
   */
  const getTrialSelectRadioSeenStatus = () => {
    if (!window.ableToGetTrial) {
      return false;
    }

    const $selectedPlanInput = $(selectedPlanInput);
    const isTrialRadioEnabled = $selectedPlanInput.attr(planAttr.isTrialRadioEnabled);
    const trialDuration = parseInt($selectedPlanInput.attr(planAttr.trialDuration));

    if (isTrialRadioEnabled === 'False' || trialDuration === 0) {
      return false;
    }

    return true;
  };

  /**
   * show/hide toggle trial radio btn block
   */
  const toggleIsTrialSelectRadio = () => {
    const isTrialAvailable = getTrialSelectRadioSeenStatus();
    const buttonsBlockClass = '_no-trial-radio';

    if (!isSpecialCouponApplied && isTrialAvailable) {
      $isTrialActiveRadioWrapper.show();
      $buttonsBlockWrapper.removeClass(buttonsBlockClass);
      return;
    }

    $isTrialActiveRadioWrapper.hide();
    $buttonsBlockWrapper.addClass(buttonsBlockClass);
  };

  const disableTrialApplyRadioBtn = () => {
    $isTrialActiveRadio.attr('checked', false);
    $isTrialActiveRadio.prop('disabled', true);
  };

  const enableTrialApplyRadioBtn = () => {
    $isTrialActiveRadio.attr('checked', true);
    $isTrialActiveRadio.prop('disabled', false);
  };

  const initReviewSlider = () => {
    if (!$reviewsCarousel.length) return;

    return new Promise((resolve) => {
      // callback for the first time Slick initializes
      $reviewsCarousel.on('init', () => {
        $reviewsSliderLoader.hide();
        $reviewsCarousel.show();
        resolve();
      });

      $reviewsCarousel.not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        swipeToSlide: true,
        adaptiveHeight: true,
        responsive: [{
          breakpoint: 992,
          settings: {slidesToShow: 2}
        }, {
          breakpoint: 620,
          settings: {slidesToShow: 1}
        }]
      });
    });
  };

  const initTooltipLinkVisibility = () => {
    if (!$ebloxInfoSection.length) $(ebloxTooltipLink).hide();
  };

  const onPageLoad = () => {
    doCheckForPreselectedPlan();
    initFirstSectionUI();
  };

  const applyProvidedCoupon = () => {
    const providedCoupon = getUrlParam(GET_PARAMS.COUPON);
    if (!providedCoupon) return;
    $couponInput.val(providedCoupon);
    $(toggleCouponBlockBtn).click();
  };

  const getEbloxPriceInCents = () => {
    const result = parseInt(ebloxAddonPrice);
    return result;
  };

  const addEbloxDataUItoPaymentStep = () => {
    // set eblox price in UI
    const ebloxPrice = (getEbloxPriceInCents() / 100).toFixed(2).toString();
    $ebloxPrice.html(`${CURRENCY}${ebloxPrice}`);

    updateUIbyShippingPrice();

    // toggle eblox & shipping prices
    $shippingForm.show();
    $ebloxRow.show();
    $shippingRow.show();
  };

  const removeEbloxDataUIFromPaymentStep = () => {
    $shippingForm.hide();
    $shippingRow.hide();
    $shippingPrice.html('');
    $ebloxRow.hide();
    $ebloxPrice.html('');
    updateUIbyShippingPrice();
  };

  const updateUIbyShippingPrice = () => {
    const shippingPriceInCents = getShippingPriceInCents(shippingCountriesSelect);
    const formattedShippingPrice = (shippingPriceInCents / 100).toFixed(2).toString();
    $shippingPrice.html(`${CURRENCY}${formattedShippingPrice}`);
  };

  const getTotalPrices = () => {
    const isTrialEnabled = isTrialActive();
    const planFullPriceInCents = $(selectedPlanInput).attr(planAttr.price);
    const totalPrices = getTotalPricesInCents(
      planFullPriceInCents,
      isTrialEnabled,
      isEbloxAdded,
      activeCoupon,
      amountOff,
      shippingCountriesSelect
    );

    return totalPrices;
  };

  const clearComponentVariables = () => {
    activeCoupon = null;
    amountOff = 0;
    isSpecialCouponApplied = false;
    specialCouponTrialDuration = null;
    removedSubscriptions = [];
  };

  const setTotalPriceToUI = (totalPriceInCents) => {
    const currencyPrice = getCurrencyPrice(totalPriceInCents);
    $planTotalPrice.html(currencyPrice);
  };

  const getPurchaseRequestData = (paymentMethodNonce, slug) => {
    const result = {
      payment_method_nonce: paymentMethodNonce,
      plan_slug: slug,
      source: 'main-flow-offer',
      discount_id: activeCoupon,
      trial_enabled: isTrialActive(),
      note: $noteFormInput.val() ? $noteFormInput.val() : ''
    };

    if (isEbloxAdded) {
      // collect eblox addon & shipping data
      const formFields = {};
      const shippingFormDataArray = $shippingFormItem.serializeArray();

      shippingFormDataArray.forEach((item) => {
        formFields[item.name] = item.value;
      });

      result.shipping_address = formFields;
      result.addons = [{addon_id: ebloxAddonId}, {addon_id: shippingAddonId}];
    }

    return result;
  };

  const dropinTokenCallback = () => {
    if (!window.braintree) {
      goToPaymentErrorHandler();
      return;
    }

    toggleButtonLoading($goToPaymentStepBtn, false);
    prepareSecondStep();
  };

  const goToPaymentErrorHandler = (err) => {
    toastr.error(getError(err), '', {timeOut: 3000});
    toggleButtonLoading($goToPaymentStepBtn, false);
  };

  const doSignUp = () => {
    ga('send', 'event', 'Registration', 'Go', 'Parent');

    $.ajax({
      url: ENDPOINT.USER_PROFILE,
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify({
        account_type: ACCOUNT_TYPES.PARENT,
        email: null,
        need_to_login: true,
        source: getUserSources('source'),
        medium: getUserSources('medium'),
        campaign: getUserSources('campaign'),
        placement: getUserSources('placement')
      })
    }).then(() => {
      window.isLoggedIn = true;
      $.getScript(
          DROPIN_SCRIPT_URL
        ).done(() => {
          handleDropinToken(dropinTokenCallback, goToPaymentErrorHandler);
        });
    }, (error) => goToPaymentErrorHandler(error));
  };

  const prepareSecondStep = () => {
    if (isEbloxNoteInputSeen) {
      parsleyNoteForm = $noteForm.parsley();
      parsleyNoteForm.validate();
      const isNoteFormValid = parsleyNoteForm.isValid();
      if (!isNoteFormValid) return;
    }

    scrollToHashedElement(subscriptionPlansHash, {scrollTimeout: 0});
    $submitPaymentBtn.hide();
    setMainTitle(section.second);
    selectSection(section.second);

    const offer = $(selectedPlanInput);
    const {
      planPeriod,
      planFullPrice,
      planFormattedNewPrice,
      planSlug,
      planBackUrl,
      planPeriodDisplay
    } = getSelectedOfferDetails(offer, amountOff, planAttr);
    const planTrialPeriod = (isSpecialCouponApplied && specialCouponTrialDuration)
        ? specialCouponTrialDuration
        : offer.attr(planAttr.trialDuration);

    setSelectedPlanDataToUI(
      planPeriod,
      planFullPrice,
      planTrialPeriod,
      planFormattedNewPrice,
      planPeriodDisplay
    );

    const { totalPriceInCents, priceFor3DSecure } = getTotalPrices();

    setTotalPriceToUI(totalPriceInCents);

    triggerGAEventOnGoToPayment(planPeriod);

    initBraintreeDropin(totalPriceInCents, priceFor3DSecure, planSlug, planBackUrl);
  };

  const handleProgressBar = () => {
    const isStepsShownStr = $(selectedPlanInput).attr(planAttr.showOnboardingSteps);
    storage.set(LOCAL_STORAGE_KEYS.IS_SHOW_STEP_PROGRESS_BAR, isStepsShownStr);

    toggleStepProgressBar($stepProgressBar);
  };

  const initBraintreeDropin = (totalPriceInCents, priceFor3DSecure, slug, backUrl) => {
    if (!isEbloxAdded) $addonOnPaymentStep.show();

    if (braintree && $(braintreeDropin).length) {
      const totalPriceInDollars = (totalPriceInCents / 100).toFixed(2);
      const braintreeConf = braintreeDropinConfig(totalPriceInDollars, braintreeDropin);

      braintree.dropin.create(braintreeConf,
        function (createErr, instance) {
          if (createErr) {
            // An error in the create call is likely due to
            // incorrect configuration values or network issues.
            // An appropriate error will be shown in the UI.

            $subscriptionError.html(
              `${createErr.message}
               <br>${CUSTOM_MESSAGE.BRAINTREE_CREATE_ERROR}`
            );
            $subscriptionError.show();
            toggleButtonLoading($prevSectionButton, false);
            // eslint-disable-next-line no-console
            console.error('dropin create error: ', createErr);
            return;
          }

          dropinInstance = instance;

          if (instance.isPaymentMethodRequestable()) {
            // This will be true if you generated the client token
            // with a customer ID and there is a saved payment method
            // available to tokenize with that customer.
            $submitPaymentBtn.show();
          }

          instance.on('paymentMethodRequestable', () => {
            $submitPaymentBtn.show();
            $subscriptionError.hide();
          });

          instance.on('noPaymentMethodRequestable', () => {
            $submitPaymentBtn.hide();
            $subscriptionError.hide();
          });

          instance.on('paymentOptionSelected', () => {
            $subscriptionError.hide();
            if (instance.isPaymentMethodRequestable()) {
              $submitPaymentBtn.show();
              return;
            }
            $submitPaymentBtn.hide();
          });

          toggleButtonLoading($prevSectionButton, false);
          toggleButtonLoading($submitPaymentBtn, false);

          $submitPaymentBtn.click(() => {
            // To prevent bot spamming we use solution from
            // https://www.thryv.com/blog/honeypot-technique/
            const $hiddenInput = $(hiddenInputSelect);
            const hiddenInputVal = $hiddenInput.val();
            if (hiddenInputVal) return;


            if (isEbloxAdded) {
              const shippingFormItemParsley = $shippingFormItem.parsley();
              if (!shippingFormItemParsley.isValid()) {
                shippingFormItemParsley.validate();
                return;
              }
            }

            $subscriptionError.hide();
            $prevSectionButton.hide();
            toggleButtonLoading($submitPaymentBtn, true);

            const priceFor3DSecureStr = (priceFor3DSecure / 100).toFixed(2).toString();
            const threeDSecureConf = threeDSecureConfig(priceFor3DSecureStr);

            instance.requestPaymentMethod({
              threeDSecure: threeDSecureConf
            }, function (requestPaymentMethodErr, payload) {
              if (requestPaymentMethodErr) {
                // eslint-disable-next-line no-console
                console.error('requestPaymentMethodErr: ', requestPaymentMethodErr);

                $subscriptionError.text(requestPaymentMethodErr.message);
                $subscriptionError.show();
                $prevSectionButton.show();
                toggleButtonLoading($submitPaymentBtn, false);
                return;
              }

              if (isThreeDSecureCanceled(payload)) {
                instance.clearSelectedPaymentMethod();
                $subscriptionError.text(CUSTOM_MESSAGE.THREE_D_SECURE_ABORT);
                $subscriptionError.show();
                toggleButtonLoading($submitPaymentBtn, false);
                $prevSectionButton.show();
                return;
              }

              const requestData = getPurchaseRequestData(payload.nonce, slug);
              $.ajax({
                url: ENDPOINT.BUY_SUBSCRIPTION,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(requestData)
              })
                .then((response) => {
                  const callback = () => {
                    if (window.ableToGetTrial && window.user.email) {
                      window.location = `/${URLS.SIGN_UP_PARENT_COMPLETE}/`;
                    } else {
                      window.location = backUrl;
                    }
                  };

                  triggerGAEventOnCheckout();
                  triggerAnalyticsOnCheckout(response, slug, callback);
                }, (errorResp) => {
                  instance.clearSelectedPaymentMethod();
                  $subscriptionError.text('');
                  $subscriptionError.append(getError(errorResp));
                  $subscriptionError.show();

                  toggleButtonLoading($submitPaymentBtn, false);
                });
            });
          });
        });
    }
  };

  // event listeners
  $(document)
    // on change country in shipping form
    .on('change', shippingCountriesSelect, () => {
      const {totalPriceInCents, priceFor3DSecure} = getTotalPrices();
      const offer = $(selectedPlanInput);
      const {
        planSlug,
        planBackUrl,
      } = getSelectedOfferDetails(offer, amountOff, planAttr);

      $submitPaymentBtn.off('click');

      dropinInstance.teardown((teardownErr) => {
        if (teardownErr) {
          // eslint-disable-next-line no-console
          console.error('dropin teardown error: ', teardownErr);
        } else {
          initBraintreeDropin(totalPriceInCents, priceFor3DSecure, planSlug, planBackUrl);
        }
      });

      updateUIbyShippingPrice();
      setTotalPriceToUI(totalPriceInCents);
      scrollToHashedElement(subscriptionPlansHash, {scrollTimeout: 0});
    })
    .on('change', isTrialActiveRadio, () => {
      toggleTrialNoteText();
    })
    // on change coupon input value
    .on('input', couponInput, () => {
      $couponInput.parsley().reset();
      toggleApplyCouponBtn($couponInput, $applyCoupon, couponBlockParams);
    })
    // apply coupon
    .on('click', applyCoupon, (e) => {
      e.preventDefault();
      // To prevent bot spamming we use solution from https://www.thryv.com/blog/honeypot-technique/
      const hiddenInputVal = $('[data-selector="parent-coupon-check-special-input"]').val();
      if (hiddenInputVal) return;

      if (!isFormValid($couponForm)) return;

      const coupon = $couponInput.val().trim();
      $couponError.hide();

      if (coupon) {
        toggleButtonLoading($applyCoupon, true);

        // check is coupon a distributor code
        doCheckIsCouponDistributorCode(coupon)
          .then(() => {
            toggleButtonLoading($applyCoupon, false);
            $licenseActivationCodeModal.modal('show');
          }, () => {
            toggleButtonLoading($applyCoupon, false);
          });

        $.post(ENDPOINT.COUPON_VALIDATE, {
          coupon: coupon
        }).then((response) => {
          toggleButtonLoading($applyCoupon, false);

          activeCoupon = coupon;
          amountOff = response.amount_off;
          removedSubscriptions = response.exclude_subscriptions;
          const isCouponValid = checkCouponApplicability();
          $applyCoupon.prop('disabled', false);
          $couponInput.parsley().removeError('coupon');
          $couponInput.prop('disabled', true);
          $applyCoupon.hide();
          $removeCoupon.show();
          $couponMessage.html(response.description);
          $couponMessage.show();
          $trialNoteText.hide();

          if (isCouponValid) ga('send', 'event', 'Button', 'CouponApplied', 'Parent');
          isSpecialCouponApplied = response.has_trial;
          specialCouponTrialDuration = response.trial_duration;

          toggleTrialNoteText();
          $trialText.html(getTrialDurationText());

          if (isCouponValid && EBLOX_COUPONS.indexOf(coupon.toLowerCase()) > -1) {
            isEbloxNoteInputSeen = true;
            $noteForm.show();
          }

          if (isSpecialCouponApplied) {
            toggleIsTrialSelectRadio();
            return;
          }

          disableTrialApplyRadioBtn();
        }, (error) => {
          toggleButtonLoading($applyCoupon, false);

          activeCoupon = null;
          $couponMessage.empty();
          $couponMessage.hide();
          const errorObject = JSON.parse(error.responseText);

          if (errorObject.error) {
            $couponInput.parsley().removeError('coupon');
            $couponInput.parsley().addError('coupon', {
              message: errorObject.error
            });
          }
        });
      }
    })
    // remove coupon applied to the selected plan
    .on('click', removeCoupon, (e) => {
      e.preventDefault();
      enableTrialApplyRadioBtn();
      toggleTrialNoteText();
      toggleIsTrialSelectRadio();

      clearComponentVariables();
      $couponInput.parsley().reset();
      $couponInput.prop('disabled', false);
      $couponInput.val('');
      $applyCoupon.show();
      $removeCoupon.hide();
      $couponMessage.empty();
      $couponMessage.hide();
      checkCouponApplicability();
      $trialText.html(getTrialDurationText());

      $noteFormInput.val('');
      $noteForm.hide();
      isEbloxNoteInputSeen = false;
      if (parsleyNoteForm && parsleyNoteForm.reset) parsleyNoteForm.reset();
    })
    // show/hide apply coupon form
    .on('click', toggleCouponBlockBtn, (e) => {
      e.preventDefault();
      toggleCouponBlock($couponBlock, $toggleCouponBlockBtn, couponBlockParams);
    })
    // on click plan item
    .on('click', planInput, () => {
      toggleButtonLoading($goToPaymentStepBtn, true);

      toggleIsTrialSelectRadio();
      setTrialDurationToTrialRadioBtnLabel();
      toggleTrialNoteText();
      checkCouponApplicability();
      setStyleSelectedPlan();

      isEbloxAdded = $ebloxAddonCheckbox.is(':checked');

      // go to second payment step
      if (!window.isLoggedIn) {
        doSignUp();

      } else {
        $.getScript(
          DROPIN_SCRIPT_URL
        ).done(() => {
          handleDropinToken(dropinTokenCallback, goToPaymentErrorHandler);
        });
      }
    })
    // click on tooltip link
    .on('click', ebloxTooltipLink, () => {
      scrollToHashedElement(ebloxInfoSectionHash, {scrollTimeout: 0});
    })
    // go to first step (select plan)
    .on('click', prevSectionButton, () => {
      scrollToHashedElement(subscriptionPlansHash, {scrollTimeout: 0});
      selectSection(section.first);
      setMainTitle(section.first);

      $(ebloxAddonPaymentStepCheckbox).prop('checked', false);
      $addonOnPaymentStep.hide() ;
    })
    // toggle eblox addon checkbox on payment step
    .on('change', ebloxAddonPaymentStepCheckbox, () => {
      isEbloxAdded = $(ebloxAddonPaymentStepCheckbox).is(':checked');

      const {totalPriceInCents, priceFor3DSecure} = getTotalPrices();
      const offer = $(selectedPlanInput);
      const {
        planSlug,
        planBackUrl,
      } = getSelectedOfferDetails(offer, amountOff, planAttr);

      $submitPaymentBtn.off('click');

      dropinInstance && dropinInstance.teardown((teardownErr) => {
        if (teardownErr) {
          // eslint-disable-next-line no-console
          console.error('dropin teardown error: ', teardownErr);
        } else {
          initBraintreeDropin(totalPriceInCents, priceFor3DSecure, planSlug, planBackUrl);
        }
      });

      updateUIbyShippingPrice();
      setTotalPriceToUI(totalPriceInCents);
      scrollToHashedElement(subscriptionPlansHash, {scrollTimeout: 0});

      if (isEbloxAdded) {
        addEbloxDataUItoPaymentStep();
        return;
      }

      removeEbloxDataUIFromPaymentStep();
    })
    // go to second step (checkout)
    .on('click', goToPaymentStepBtn, (e) => {
      e.preventDefault();
      toggleButtonLoading($goToPaymentStepBtn, true);
      isEbloxAdded = $ebloxAddonCheckbox.is(':checked');

      if (!window.isLoggedIn) {
        doSignUp();

      } else {
        $.getScript(
          DROPIN_SCRIPT_URL,
          handleDropinToken(dropinTokenCallback, goToPaymentErrorHandler)
        );
      }
    });

  onPageLoad();
});
